import styled from "styled-components";

const ProductsSC = styled("section")`
  color: #0c0e20;

  /* width: calc(100% - 172px * 2); */
  /* width: 85%; */
  margin: 0 auto;

  background: #f5f6f8;
  padding: 48px 72px 48px 72px;

  @media (max-width: 1024px) {
    padding: 0;
    width: 100%;
    padding: 48px 8px 76px 8px;
  }
  @media (max-width: 950px) {
    /* width: calc(100% - 15px * 2); */
    /* padding: 48px 40px 76px 40px; */
  }
  @media (max-width: 812px) {
    /* width: calc(100% - 15px * 2);
    padding: 0.94rem 0.31rem 0.31rem;

    margin-bottom: 1.87rem; */
  }
`;

const ProductsTitleSC = styled("h2")`
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 5rem;

  text-align: center;

  margin-bottom: 6rem;

  @media (max-width: 1024px) {
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.4375rem;

    margin-bottom: 5rem;
  }
  @media (max-width: 1024px) {
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.4375rem;

    margin-bottom: 2.81rem;
  }
`;

const ProductContainerSC = styled("div")`
  display: grid;
  margin: 0 auto;
  /* grid-template-columns: repeat(5, 1fr); */
  /* grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr)); */
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(3, minmax(0, 1fr));

  /* gap: 48px; */
  grid-gap: 2rem;

  justify-items: center;
  align-items: center;

  @media (max-width: 1920px) {
    /* grid-template-columns: repeat(auto-fit, minmax(24.5rem, 1fr)); */
    /* grid-template-columns: repeat(3, 1fr); */
  }
  @media (max-width: 1420px) {
    /* grid-template-columns: repeat(auto-fit, minmax(20.5rem, 1fr)); */
    grid-gap: 2.5rem;

    /* grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr)); */
    /* grid-template-columns: repeat(2, 1fr); */
  }
  @media (max-width: 1224px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.94rem;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.94rem;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0.94rem;
  }

  /* @media (max-width: 1920px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
    gap: ;
  } */

  /* @media (max-width: 1428px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1394px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1078px) {
    grid-template-columns: repeat(2, 1fr);
  } */
  /* @media (max-width: 812px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.51rem;
  }
  @media (max-width: 430px) {
    grid-template-columns: repeat(1, 1fr);
  } */
`;

const TextContainerSC = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

const SubtitleSC = styled.p`
  font-size: 16px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const useProductsStyles = () => ({
  ProductsSC,
  ProductsTitleSC,
  ProductContainerSC,
  TextContainerSC,
  SubtitleSC,
});
