import { useEffect, useRef, useState } from "react";

import { useProductsStyles } from "./ProductsStyles";
import ProductCard from "components/ProductCard";
import { useProducts } from "./useProducts";
import FullVisionCard from "components/ProductCard/FullVision";
import useStore from "../../store";

const Products = () => {
  const { divRef, selectedProduct, setSelectedProduct, cards } = useProducts();
  const { data } = useStore();

  // const divRef = useRef(null);
  // const [selectedProduct, setSelectedProduct] = useState(null);

  /* function handleClick(props) {
    setSelectedProduct(props);
  }

  useEffect(() => {
    const div = divRef.current;

    const windowSize = () => {
      if (window.innerWidth <= 812) {
        div.addEventListener("click", handleClick);
      } else {
        div.removeEventListener("click", handleClick);
      }
    };
    window.addEventListener("resize", windowSize);
    return () => window.removeEventListener("resize", windowSize);
  }, []); */

  // const CasperElements = cards.map((el, i) => (
  //   <ProductCard {...el} key={el.id} />
  // ));

  const CasperElements = cards.map((item, i) => {
    const isAdded = data.some((itemBask) => itemBask.name === item.title);

    return <ProductCard key={item.id} isAdded={isAdded} {...item} />;
  });

  return (
    <>
      <ProductsSC>
        <ProductsTitleSC>Продукция</ProductsTitleSC>
        <ProductContainerSC ref={divRef}>{CasperElements}</ProductContainerSC>
      </ProductsSC>
      <TextContainerSC style={{ flexDirection: "column" }}>
        <SubtitleSC>
          454092, Челябинская область, г Челябинск, ул. Кирова, дом 130, корпус
          1
        </SubtitleSC>
        <SubtitleSC>ИП Пушкин Ярослав Сергеевич</SubtitleSC>
        <SubtitleSC>ИНН: 660205793468</SubtitleSC>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            paddingTop: "24px",
            gap: "24px",
            flexWrap: "wrap",
          }}>
          <img
            src="/Logotypes/mastercard.png"
            alt="Mastercard"
            style={{ height: "46px" }}
          />

          <img
            src="/Logotypes/visa.png"
            alt="Visa"
            style={{ height: "46px" }}
          />
          <img src="/Logotypes/mir.png" alt="MIR" style={{ height: "46px" }} />
          <img
            src="/Logotypes/Уралсиб%20-%20Deep%20Purple%20-%20Gorizontal%20(PNG).png"
            alt="Уралсиб"
            style={{ height: "46px" }}
          />
        </div>
      </TextContainerSC>
    </>
  );
};

const {
  ProductsSC,
  ProductsTitleSC,
  TextContainerSC,
  SubtitleSC,
  ProductContainerSC,
} = useProductsStyles();

export default Products;
